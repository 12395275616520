import MarkerClusterGroup from "react-leaflet-cluster";
import React, { useEffect, useState } from "react";
import "leaflet/dist/leaflet.css";
import 'react-leaflet-markercluster/dist/styles.min.css';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
// import "leaflet/dist/leaflet.css";
import L from 'leaflet';
import ContentSection from "../Content";
import Select from 'react-select';
import axios from "axios";
import BaseUrl from "../../utils/BaseUrl";
import moment from "moment/moment";

// import MarkerCluster from "./Clusters";

const position = [5.318531667, -4.019558333];
const mapStyle = { height: "100vh", width: "100%" ,position : "relative"};

const url = BaseUrl();
const Leaflet = () => {
  // let markers;

  // const addMarkers = () => {
  //   markers = [];
  //   for (let i = 0; i < 10000; i++) {
  //     markers.push({
  //       position: {
  //         lng: -122.673447 + Math.random() * 200.0,
  //         lat: 45.5225581 - 60 + Math.random() * 80
  //       }
  //     });
  //   }
  // };

  // addMarkers();
  const [loading,setLoading] = useState(false);
  const [itemsList,setItemsList] = useState([]);
  const [selectPdv,setSelectPdv] = useState(null);
  const [map, setMap] = useState(null);

  const customMarkerIcon = L.icon({
      iconSize: [20, 29],
      iconAnchor: [10, 41],
      popupAnchor: [2, -40],
      iconUrl: "https://unpkg.com/leaflet@1.6/dist/images/marker-icon.png",
      shadowUrl: "https://unpkg.com/leaflet@1.6/dist/images/marker-shadow.png",
  });
  
  const markers = [
    [51.505, -0.09],
    [51.51, -0.1],
    [51.51, -0.12],
    [51.5, -0.08],
    [51.49, -0.1],
  ]; 

  useEffect(()=>{
    fetchItemsList();
  },[map]);

  const fetchItemsList = async ()=>{
    setLoading(true);
    try {
        axios.get(url.base+'/contrat-en-list',{
            headers:{
                'Content-Type':'application/json',
                "Authorization": `Bearer ${localStorage.getItem('_token_')}`
                
            },
        }).then((resp)=>{

            if(resp.status == 200){
                setItemsList(resp.data.data);
            }
            setLoading(false);
        })
    } catch (error) {
        setLoading(false);
    }
}

const optionpvs = itemsList?.map((option)=>({
  label : `${option.d_pdv}`,
  value : `${option.code}`
}));

const handleChangePdv=(selectOption)=>{
  setSelectPdv(selectOption.value);
}

  return (
     <ContentSection ulShownav={"gest-affectations"} navactive={"geolocalisation"}>
      <div>
      
         <div className="pb-3">
              <select className="form-control">
                  <option value="" selected disabled>Select un point de vente</option>
                  {itemsList?.map((option,index)=>
                    <option 
                    value={option.code}
                    onClick={()=> {
                      map.flyTo([option.d_latitude,option.d_longitude], 15)
                    }}
                    >{option.d_pdv}</option>
                  )}
                  
              </select>
        </div>         
         <div class="p-2 flex-fill bd-highlight ">
         <MapContainer center={position} zoom={10} style={mapStyle} scrollWheelZoom={false} ref={setMap}>
              <TileLayer
                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
              />
              <MarkerClusterGroup chunkedLoading>
                {itemsList.map((item, idx) => (
                  <Marker key={idx} position={[item.d_latitude,item.d_longitude]} icon={customMarkerIcon}>
                    <Popup>
                    <table class="table table-striped table-bordered">
                        <thead >
                            <tr className={moment(item.contrat_date_fin).isBefore(moment()) ? "bg-danger" :""}>
                              {moment(item.contrat_date_fin).isBefore(moment()) ? <b className="text-white text-center">Contrat Expiré</b> :""}
                            </tr>
                        </thead>
                        <tbody >
                            <tr>
                                <th scope="col"><b>Nom du PDV</b></th>
                                <td class="text-uppercase"><strong>{item.d_pdv}</strong></td>
                            </tr>
                            <tr>
                                <th scope="col"><b>Contact</b></th>
                                <td class="text-uppercase"><strong>{item.d_contact}</strong></td>
                            </tr>
                             <tr>
                                <th scope="col"><b>Territoire </b></th>
                                <td class="text-uppercase"><strong>{item.d_territoire}</strong></td>
                            </tr>
                            <tr>
                                <th scope="col"><b>Date debut </b></th>
                                <td class="text-uppercase"><strong>{moment(item.contrat_date_debut).format("Do MMMM YYYY")}</strong></td>
                            </tr>
                            <tr >
                                <th scope="col" ><b>Date fin </b></th>
                                <td class="text-uppercase"><strong>{moment(item.contrat_date_fin).format("Do MMMM YYYY")}</strong></td>
                            </tr>
                             <tr>
                                <th scope="col"><b>Durée du contrat</b></th>
                                <td class="text-uppercase"><strong>{moment(item.contrat_date_fin).diff(item.contrat_date_debut,"days")} Jour(s)</strong></td>
                            </tr>

                            <tr>
                                <th scope="col"><b>Statut</b></th>
                                <td class="text-uppercase"><strong>
                                {item.statut_contrat == 3 && (
                                  <span className="badge bg-success">Actif</span>
                                  )}
                                  {item.statut_contrat == 4 && (
                                      <span className="badge bg-danger">Inactif</span>
                                  )}
                                  </strong></td>
                            </tr>
                            
             

                            
                        </tbody>
                      </table>

                    </Popup>
                  </Marker>
                ))}
              </MarkerClusterGroup>          
        </MapContainer>
          </div>
          {/* <div class="p-2 flex-fill bd-highlight">
          <Select />
          </div>  */}
      </div>


      </ContentSection>
  );
};

export default Leaflet;
